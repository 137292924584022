import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import { YMD } from '@/constant/timeFormat';
import getTemperatureLayer from '@/utils/temperatureLayer';
import {
  TASK_TYPE,
  TASK_STATUS,
  BIZ_TYPE,
  OPERATION_TYPE,
  TASK_TYPE_ENUM,
  TASK_STATUS_ENUM,
  BIZ_TYPE_ENUM,
  OPERATION_TYPE_ENUM,
  STAFF_TYPE_ENUM,
  ASSIGN_STATUS,
} from './constant';

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '任务号',
  prop: 'taskNo',
  minWidth: 220,
  url: 'detail',
}, {
  label: '任务类型',
  prop: 'taskType',
  minWidth: 180,
  formatter: (row) => TASK_TYPE_ENUM[row.taskType],
}, {
  label: '作业方式',
  prop: 'operationType',
  minWidth: 180,
  formatter: (row) => OPERATION_TYPE_ENUM[row.operationType],
}, {
  label: '货主',
  prop: 'shippers',
  minWidth: 180,
  formatter: (row) => row.shippers.map((item) => item.shipperName).join('，'),
  showOverflowTooltip: true,
}, {
  label: '任务状态',
  prop: 'taskStatus',
  minWidth: 180,
  formatter: (row) => TASK_STATUS_ENUM[row.taskStatus],
}, {
  label: '温层',
  prop: 'temperatureLayers',
  minWidth: 180,
  formatter: (row) => row.temperatureLayers.map((item) => getTemperatureLayer(item)).join('，'),
  showOverflowTooltip: true,
}, {
  label: '来源单据类型',
  prop: 'bizType',
  minWidth: 180,
  formatter: (row) => BIZ_TYPE_ENUM[row.bizType],
}, {
  label: '来源单据号',
  prop: 'bizNo',
  minWidth: 180,
}, {
  label: '创建人',
  prop: 'createUserName',
  minWidth: 180,
}, {
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '作业人',
  prop: 'operatorStaffName',
  minWidth: 180,
}, {
  label: '作业开始时间',
  prop: 'startTime',
  ...DateTime,
}, {
  label: '作业完成时间',
  prop: 'endTime',
  ...DateTime,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 420,
}];
export const TASK_LIST_USER_TABLECOLUMN = [{
  label: '员工工号',
  prop: 'staffCode',
  minWidth: 180,
}, {
  label: '员工姓名',
  prop: 'staffName',
  minWidth: 180,
}, {
  label: '岗位',
  prop: 'positionName',
  minWidth: 180,
}, {
  label: '员工类型',
  prop: 'staffType',
  minWidth: 180,
  ENUM: STAFF_TYPE_ENUM,
}, {
  label: '当前待办任务数',
  prop: 'taskNum',
  minWidth: 180,
}];
export const taskListUserSerchFields = [{
  label: '员工工号',
  prop: 'staffCode',
  component: FormType.INPUT,
}, {
  label: '员工姓名',
  prop: 'staffName',
  component: FormType.INPUT,
}];
export const serchFields = [
  {
    label: '任务号',
    prop: 'taskNo',
    component: FormType.INPUT,
  }, {
    label: '任务类型',
    prop: 'taskType',
    component: FormType.SELECT,
    options: TASK_TYPE,
  }, {
    label: '作业方式',
    prop: 'operationType',
    component: FormType.SELECT,
    options: OPERATION_TYPE,
  }, {
    label: '任务状态',
    prop: 'taskStatus',
    component: FormType.SELECT,
    options: TASK_STATUS,
  }, {
    label: '来源单据类型',
    prop: 'bizType',
    component: FormType.SELECT,
    options: BIZ_TYPE,
  }, {
    label: '来源单据号',
    prop: 'bizNo',
    component: FormType.INPUT,
  }, {
    label: '分配状态',
    prop: 'assignStatus',
    component: FormType.SELECT,
    options: ASSIGN_STATUS,
  }, {
    label: '创建时间',
    prop: 'createTimeInside',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      valueFormat: YMD,
    },
  }, {
    label: '作业人',
    prop: 'operatorNameSearch',
    component: FormType.INPUT,
  }, {
    label: '作业完成时间',
    prop: 'endTimeInside',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      valueFormat: YMD,
    },
  },
];

export const modifyFormFileds = [
  {
    prop: 'oldOperationType',
    label: '原作业方式',
  },
  {
    prop: 'operationType',
    label: '更改为',
    component: 'el-select',
    componentAttrs: {
      placeholder: '请选择作业状态',
    },
    subComponent: 'el-option',
    options: OPERATION_TYPE,
    formItemAttrs: {
      rules: [
        { required: true, message: '请选择', trigger: 'blur' },
      ],
    },
  },
];
