<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleQueryFrom"
      @reset="handleQueryFrom"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.loadTaskPage"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
    >
      <template #taskNo="{row}">
        <nh-link
          to=""
          :text="row.taskNo"
          @click="handleShowDetailDialog(row)"
        />
        <span
          v-if="row.important === IMPORTANT_ENUM_VALUE.URGENT"
          class="task-urgent"
        > 加急</span>
      </template>
      <template #action="{row}">
        <nh-button
          v-if="isShowOperationButton(row)"
          type="text"
          @click="handleShowModifyOperationDialog(row)"
        >
          更改作业方式
        </nh-button>
        <nh-button
          v-if="!row.operatorStaffId"
          type="text"
          :loading="loading.loadTasksListUser"
          @click="handleTaskAllot(row)"
        >
          任务分配
        </nh-button>
        <nh-button
          v-if="isShowResetAssignation(row)"
          type="text"
          :loading="loading.loadTasksListUser"
          @click="handleTaskAllot(row)"
        >
          任务重分配
        </nh-button>
        <nh-button
          v-if="row.important === IMPORTANT_ENUM_VALUE.NORMAL"
          type="text"
          :loading="loading.handleChangeImportant"
          @click="handleChangeImportant(row)"
        >
          加急
        </nh-button>
        <nh-button
          v-if="row.operationType === OPERATION_TYPE_ENUM_VALUE.PAPER"
          type="text"
          :loading="loading.handleTasksFastOver"
          @click="handleTasksFastOver(row)"
        >
          纸单确认
        </nh-button>
        <nh-button
          v-if="row.taskType in PRINT"
          type="text"
          @click="handlePrintJobList(row)"
        >
          打印任务单
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <TaskDetail ref="taskDetail" />
  <nh-dialog
    v-model="taskListUserDialogVisible"
    title="任务分配/重分配"
    custom-class="custom-dialog_50"
    :track="{
      name_zh: `任务中心/任务管理/弹窗-任务分配`,
      trackName: $route.path
    }"
  >
    <pl-block>
      <PlForm
        v-model="taskListUserData"
        mode="search"
        :form-props="{labelWidth:'80px'}"
        :fields="taskListUserSerchFields"
        @submit="handleTaskListUsersQueryFrom"
        @reset="handleTaskListUsersQueryFrom"
      />
    </pl-block>
    <pl-block class="detail-height">
      <el-table
        v-loading="loading.loadTasksListUser"
        :data="taskListUsers"
        border
        class="flex_1"
      >
        <nh-table-column
          key="a"
          prop="a"
          :width="45"
        >
          <template #default="scope">
            <div class="radio-box">
              <el-radio
                v-model="taskListUsersRadio"
                :label="scope.row.staffId"
                @change="handleSelectUser(scope.row)"
              />
            </div>
          </template>
        </nh-table-column>
        <nh-table-column
          v-for="item in TASK_LIST_USER_TABLECOLUMN"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span v-if="item.ENUM">{{ item.ENUM[scope.row[item.prop]] }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </nh-table-column>
      </el-table>
    </pl-block>
    <template #footer>
      <div>
        <nh-button
          type="primary"
          :loading="loading.tasksAllot"
          @click="handleAllotSubmit"
        >
          确认分配
        </nh-button>
        <nh-button
          type="grey"
          @click="handleAllotClose"
        >
          取 消
        </nh-button>
      </div>
    </template>
  </nh-dialog>
  <nh-dialog
    v-model="modifyOperationDialogVisible"
    title="更改作业方式"
    :track="{
      name_zh: `任务中心/任务管理/弹窗-更改作业方式`,
      trackName: $route.path
    }"
  >
    <PlForm
      ref="modifyOperationForm"
      v-model="modifyOperationForm"
      mode="normal"
      :fields="modifyFormFileds"
      submit-btn-text="确 定"
      reset-btn-text="取 消"
      :collapse="false"
      @submit="handleModifyOperationTypeSubmit"
      @reset="handleModifyOperationTypeClose"
    >
      <template #oldOperationType>
        <span>{{ modifyOperationForm.oldOperationType }}</span>
      </template>
    </PlForm>
  </nh-dialog>
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import TaskDetail from './detail/index.vue';
import {
  TABLECOLUMN,
  serchFields,
  TASK_LIST_USER_TABLECOLUMN,
  taskListUserSerchFields,
  modifyFormFileds,
} from './fileds';
import {
  PRINT,
  IMPORTANT_ENUM,
  IMPORTANT_ENUM_VALUE,
  OPERATION_TYPE_ENUM,
  OPERATION_TYPE_ENUM_VALUE,
  TASK_STATUS_VALUE_ENUM,
  TASK_STATUS_ENUM_VALUE,
} from './constant';
import mixin from './mixin';
import {
  taskPage,
  tasksChangeImportant,
  tasksFastOver,
  tasksChangeOperationType,
  tasksListUser,
  tasksAllot,
} from './api';

export default {
  name: 'TaskManagement',
  components: {
    TaskDetail,
  },
  filters: {},
  mixins: [mixin, loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      PRINT,
      TASK_LIST_USER_TABLECOLUMN,
      taskListUserSerchFields,
      OPERATION_TYPE_ENUM,
      OPERATION_TYPE_ENUM_VALUE,
      IMPORTANT_ENUM,
      IMPORTANT_ENUM_VALUE,
      modifyFormFileds,
      tableData: [],
      formData: {
        assignStatus: undefined,
      },
      taskListUserDialogVisible: false,
      modifyOperationDialogVisible: false,
      taskListUsers: [],
      taskListUsersRadio: '1',
      taskListUserData: {},
      loading: {
        loadTaskPage: false,
        loadTasksListUser: false,
        pageTasksChangeOperationType: false,
        tasksAllot: false,
        handleChangeImportant: false,
        handleTasksFastOver: false,
      },
      modifyOperationForm: {},
      modifyOperationRoul: {
        oldOperationType: [{ required: false, message: '请选择分拣线', trigger: 'blur' }],
        operationType: [{ required: true, message: '请选择作业方式', trigger: 'blur' }],
      },
      waitAllotTask: null,
      selectedUser: null,
      selectTasks: [],
    };
  },
  watch: {
    pagination() {
      this.loadTaskPage();
    },
  },
  created() {
    this.loadTaskPage();
  },
  methods: {
    handleQueryFrom(data) {
      this.pagination.page = 1;
      const formData = data;
      const createTime = !(formData.createTimeInside && formData.createTimeInside.length)
        || (formData.createTimeInside
          && formData.createTimeInside instanceof Array
          && formData.createTimeInside.length < 2);
      const endTime = !(formData.endTimeInside && formData.endTimeInside.length)
        || (formData.endTimeInside
          && formData.endTimeInside instanceof Array
          && formData.endTimeInside.length < 2);
      if (createTime) {
        formData.createTimeInside = [];
      }
      if (endTime) {
        formData.endTimeInside = [];
      }
      this.formData = {
        ...formData,
        createTimeStart:
          formData.createTimeInside
          && formData.createTimeInside[0]
          && moment(formData.createTimeInside[0])
            .startOf('day')
            .valueOf(),
        createTimeEnd:
          formData.createTimeInside
          && formData.createTimeInside[1]
          && moment(formData.createTimeInside[1])
            .endOf('day')
            .valueOf(),
        endTimeStart:
          formData.endTimeInside
          && formData.endTimeInside[0]
          && moment(formData.endTimeInside[0])
            .startOf('day')
            .valueOf(),
        endTimeEnd:
          formData.endTimeInside
          && formData.endTimeInside[1]
          && moment(formData.endTimeInside[1])
            .endOf('day')
            .valueOf(),
      };
      this.loadTaskPage();
    },
    handleTaskListUsersQueryFrom(data) {
      this.taskListUserData = { ...data };
      this.loadTasksListUser(data);
    },
    async loadTaskPage() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await taskPage(pagination, this.formData);
      this.tableData = resp.records.map((item) => ({
        ...item,
      }));
      this.pagination.total = resp.total;
    },
    async handleChangeImportant(rowData) {
      await this.$confirm('是否确认加急任务？', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
      });
      await tasksChangeImportant({}, { id: rowData.id, important: IMPORTANT_ENUM_VALUE.URGENT });
      await this.$message({
        type: 'success',
        message: '操作成功!',
      });
      this.loadTaskPage();
    },
    async handleTasksFastOver(rowData) {
      const operationType = OPERATION_TYPE_ENUM[rowData.operationType]
        ? `[${OPERATION_TYPE_ENUM[rowData.operationType]}]`
        : '';
      await this.$confirm(`是否确认一键完成该${operationType}任务？`, {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
      });
      await tasksFastOver({ id: rowData.id }, null);
      await this.$message({
        type: 'success',
        message: '已完成!',
      });
      this.loadTaskPage();
    },
    handleShowModifyOperationDialog(rowData) {
      this.modifyOperationDialogVisible = true;
      this.modifyOperationForm.id = rowData.id;
      this.modifyOperationForm.oldOperationType = rowData.operationType;
    },
    async pageTasksChangeOperationType(data) {
      await tasksChangeOperationType({}, data);
    },
    async handleModifyOperationTypeSubmit() {
      await this.pageTasksChangeOperationType({ ...this.modifyOperationForm });
      this.$message({
        type: 'success',
        message: '更新成功!',
      });
      this.handleModifyOperationTypeClose();
      this.loadTaskPage();
    },
    async handleModifyOperationTypeClose() {
      this.modifyOperationDialogVisible = false;
    },
    async handleTaskAllot(rowData) {
      this.waitAllotTask = rowData;
      this.taskListUserDialogVisible = true;
      this.taskListUsersRadio = '1';
      this.selectedUser = null;
      this.loadTasksListUser({ warehouseCode: rowData.warehouseCode });
    },
    handleAllotSubmit() {
      if (this.selectedUser) {
        this.tasksAllot();
      } else {
        this.$message({ type: 'warning', message: '请选择一个用户后再进行分配' });
      }
    },
    handleAllotClose() {
      this.taskListUserDialogVisible = false;
    },
    handlePrintJobList(rowData) {
      let path = '';
      switch (rowData.taskType) {
        case PRINT.PICKING:
          path = '/print/picking-list';
          break;
        case PRINT.PART_PICKING:
          path = '/print/sorting-list';
          break;
        default:
          return;
      }
      const routeUrl = this.$router.resolve({
        path,
        query: { waveOrderId: rowData.bizId },
      });
      window.open(routeUrl.href, '_blank');
    },
    /**
     * 是否显示 更改作业方式按钮
     * 未开始的 或 纸单 或者是 pda 的才能更改
     * 已完结 不展示更改作业方式
     */
    isShowOperationButton(rowData) {
      return (
        rowData.operationType === OPERATION_TYPE_ENUM_VALUE.PAPER
        || rowData.operationType === OPERATION_TYPE_ENUM_VALUE.PDA
        || rowData.operationType === null
        || rowData.operationType === undefined
        || rowData.taskStatus === TASK_STATUS_ENUM_VALUE.FINISHED
      );
    },
    /**
     * 是否显示重分配按钮
     * 需要是已经分配过并且 未开始、作业中状态的任务可任务重分配
     */
    isShowResetAssignation(rowData) {
      return (
        rowData.operatorStaffId
        && (rowData.taskStatus === TASK_STATUS_VALUE_ENUM.PREPARE
          || rowData.taskStatus === TASK_STATUS_VALUE_ENUM.DOING)
      );
    },
    async loadTasksListUser(data) {
      const resp = await tasksListUser({}, data);
      this.taskListUsers = resp;
    },
    handleSelectUser(rowData) {
      this.selectedUser = rowData;
    },
    async tasksAllot() {
      await tasksAllot({}, { staffId: this.selectedUser.staffId, taskId: this.waitAllotTask.id });
      this.taskListUserDialogVisible = false;
      this.$message({ type: 'success', message: '分配成功' });
      this.loadTaskPage();
    },
    handleShowDetailDialog(row) {
      this.$refs.taskDetail.showDetail(row);
    },
  },
};
</script>
<style lang="scss" scoped>
.radio-box {
  width: 15px;
  overflow: hidden;
}

.task-urgent {
  background-color: rgba(241, 75, 95, 0.97);
  color: white;
  padding: 2px 8px 2px 4px;
  border-radius: 4px;
  margin-left: 4px;
}

.detail-height {
  height: 320px;
  overflow-y: auto;
}
</style>
